<template>
  <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="600px"
    >
      <v-card>
        <v-form v-model="valid">
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Gộp kiện hàng</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="submitForm(submitData)"
              :loading="loading"
              :disabled="!valid"
            >
              Gộp
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-subheader><h3>Gộp {{kien}} Kiện | Khối lượng: {{tong}} KG | Thể tích: {{tongtt}} vào HAWB</h3></v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-tabs
                  v-model="tab"
                  background-color="#0f2e8c"
                  center-active
                  dark
                >
                  <v-tab href="#old" :disabled="list.length === 0">Hawb</v-tab>
                  <v-tab href="#new">Tạo mới</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item
                    value="old"
                  >
                    <br>
                    <v-card flat>
                      <v-select
                        v-model="CapNhat.Hawb"
                        :items="list"
                        item-value="_id"
                        item-text="Code"
                        label="Mã hawb"
                        :rules="[required('Mã hawb')]"
                        @change="UpdateKL"
                      />
                      <v-text-field
                        v-model="CapNhat.MaxWeight"
                        label="Khối lượng tối đa"
                        disabled
                      />
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                    value="new"
                  >
                    <br>
                    <v-card flat>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="CapNhat.Hawb"
                            label="Mã hawb"
                            :rules="[required('Mã hawb'),onlyNumber('Mã hawb')]"
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="CapNhat.MaxWeight"
                            :prefix="TongKL"
                            suffix="KG"
                            label="Khối lượng tối đa (KG)"
                            :rules="[required('Khối lượng tối đa'),onlyNumber('Khối lượng tối đa'),min(tong)]"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-overlay
          absolute
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loading"
          />
        </v-overlay>
        </v-form>
      </v-card>
    </v-dialog>
</template>

<script>
import validations from '@/plugins/helper/validations'
export default {
  data () {
    return {
      valid:false,
      dialog: false,
      CapNhat: {
        'Hawb':'',
        'MaxWeight': 0,
      },
      tab:'',
    }
  },
  computed:{
    TongKL(){
      return `${this.tong}/`
    },
    submitData(){
      return  {...this.CapNhat,'Action':this.tab}
    }
  },
  watch: {
    enable: function(val){
      this.dialog = val
    },
    dialog: function(val){
      this.$emit("update:enable", val);
    },
    tab: function(v){
      if(v === 'new')
      {
        this.CapNhat.Hawb = ''
      }
    },
  },
  methods: {
    ...validations,
    UpdateKL(val){
      this.list.map(item=>{
        if(item._id === val){
          this.CapNhat.MaxWeight = item.MaxWeight
        }
      })
    }
  },
  mounted(){
    if(this.list.length === 0 ) this.tab='new'
  },
  props: ["enable","submitForm","loading","list","tong","kien","tongtt"]
}
</script>

<style>
</style>