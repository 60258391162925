<template>
  <v-dialog
    v-model="dialog"
    max-width="900px"
    ransition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
				:color="delivered ? 'green':'primary'"
        dark
        v-bind="attrs"
        v-on="on"
        small
        text
        @click="submit(track)"
      >
        {{ track }}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        dark
        :color="delivered ? 'green':'primary'"
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>KIỂM TRA TRACKING: {{track}}</v-toolbar-title>
      </v-toolbar>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog:false,
    }
  },

  props: ["track","submit","delivered"],
}
</script>

<style>

</style>