<template>
  <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="600px"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Cập nhật Tracking</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
						<v-btn
							dark
							text
							color="green"
							@click="Pickup"
							:loading="loading"
						>
							Nhận hàng tại kho
						</v-btn>
            <v-btn
              dark
              text
              @click="submitForm"
              :loading="loading"
            >
              Lưu
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-subheader>Tracking</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-text-field v-model="Tracking" label="Tracking" :rules="[required('Tracking')]"  />
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-select
                  v-model="ShipmentProvider"
                  :items="shipmentproviders"
                  item-value="value"
                  item-text="text"
                  label="Đơn vị vận chuyển"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-overlay
          absolute
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loading"
          />
        </v-overlay>
      </v-card>
    </v-dialog>
</template>

<script>
import validations from '@/plugins/helper/validations'
export default {
  data () {
    return {
      dialog: false,
      Tracking: '',
      ShipmentProvider: 'vnpost',
      shipmentproviders:[
        {value:"vnpost",text:"VNPOST"},
        {value:"jtexpress",text:"J&T EXPRESS"},
				//{value:"vtpost",text:"VIETTEL POST - SG"},
        {value:"vtposthn",text:"VIETTEL POST - HN"},
        {value:"vtpostsg",text:"VIETTEL POST - SG"},
        {value:"nhattin",text:"NHẤT TÍN"},
      ],
    }
  },
  watch: {
    enable: function(val){
      this.dialog = val
    },
    dialog: function(val){
      this.$emit("update:enable", val);
    },
    Tracking: function(val){
      if(val){
        this.$emit("update:tracking", val);
      }
    },
    ShipmentProvider: function(val){
      this.$emit("update:providers", val);
    },
    providers(v){
      this.ShipmentProvider = v
    },
    tracking: function(val){
      this.Tracking = val
    }
  },
  methods: {
    ...validations,
  },
  mounted(){
    this.Tracking = this.tracking
  },
  props: ["enable","submitForm","loading","tracking","providers","Pickup"]
}
</script>

<style>
</style>