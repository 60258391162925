<template>
  <v-responsive aspect-ratio="16/9">
    <v-form v-model="valid">
    <v-card>
      <v-card-title></v-card-title>
      <v-row>
        <v-col 
          cols=6
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="3"
              >
                <v-text-field
                  v-model="submitData.janCode"
                  label="Jan Code"
                  :rules="[required('Jan Code')]"
                  :disabled="mode==='edit'"
                />
              </v-col>
              <v-col
                cols="3"
              >
                <v-currency-field
                  v-model="submitData.price"
                  label="Price"
                  :rules="[required('Price')]"
                />
              </v-col>
              <v-col
                cols="3"
              >
                <v-autocomplete
                  v-model="submitData.countryCode"
                  :items="Countries"
                  item-value="code"
                  item-text="text"
                  label="Origin"
                  :rules="[required('Origin')]"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="submitData.name"
                  label="En Name"
                  rows="2"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="submitData.jaName"
                  label="JP Name"
                  rows="2"
                  :rules="[required('JP Name')]"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="submitData.viName"
                  label="Vi Name"
                  rows="2"
                  :rules="[required('Vi Name')]"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="submitData.description"
                  label="Description"
                  :rules="[required('Description')]"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="submitData.enDescription"
                  label="En Description"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="submitData.viDescription"
                  label="VI Description"
                  :rules="[required('Vi Description')]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>    
        <v-col
          cols=6
        >
          <v-card-title>
            <v-row>
              <v-col
                cols=6
              >
                <v-textarea
                  v-model="submitData.LinKSP"
                  label="Link SP"
                />
              </v-col>
              <v-col
                cols=3
              >
                <v-file-input
                  ref="inputFile"
                  show-size
                  label="Tải hình ảnh"
                  accept="image/*"
                  @change="selectFile"
                />
              </v-col>
              <v-col
                cols="auto"
              >
                <v-btn
                  :disabled="!valid"
                  color="green"
                  large
                  dark
                  @click="submit(submitData)"
                >
                  Lưu
                </v-btn>
              </v-col>
							<v-col
								cols="auto"
							>
								<v-btn
									:disabled="!valid"
									color="green"
									large
									dark
									@click="submit(submitData,true)"
								>
									Lưu & Chọn
								</v-btn>
							</v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-img v-if="preview" :src="preview" aspect-ratio="1.3" />
          </v-card-text>
        </v-col>
      </v-row>
      <Loading :status="Status" />
    </v-card>
    </v-form>
  </v-responsive>
</template>

<script>
import Countries from '@/plugins/helper/countries.json'
import validations from '@/plugins/helper/validations'
import Loading from '@/components/base/Loading.vue'
import {LinkIMG} from '@/plugins/helper/string'
export default {
  components:{
    Loading,
  },
  data () {
    return {
      mode:'new',
      valid:false,
      submitData:{
        janCode: '',
        name: '',
        jaName: '',
        viName: '',
        price: '',
        countryCode: 'JP',
        description: '',
        viDescription: '',
        enDescription: '',
        unit: 'pcs',
        file: undefined
      },
      process: 0,
      preview: undefined,
    }
  },
  watch: {
    uploadProcess(v){
      this.process = v
    },
    editItem(v){
      if(v !== undefined) this.Change(v)
    }
  },
  computed:{
    Status(){
      return !!this.status
    },
    Countries(){
      return Object.keys(Countries).map((key) => ({
        'code':key,
        'text':Countries[key]
      }))
    }
  },
  methods:{
    ...validations,
    Change(v){
      this.mode = 'edit'
      const {janCode, name, jaName, viName, price, countryCode, description, viDescription, enDescription, unit, productImages, productLinks} = v
      this.submitData = {janCode, name, jaName, viName, price, countryCode, description, viDescription, enDescription, unit, productImages, productLinks}
      if(v.productImages.length > 0){
        if(v.productImages[0].uri){
          this.preview = this.LinkIMG(v.productImages[0].uri)
        }
      }else{
        this.preview = '/no-image.png'
      }
      if(v.productLinks.length > 0){
        let linkANH = []
        this.submitData.productLinks.map(item=>{
          linkANH.push(item.link)
        })
        this.submitData.LinkSP = linkANH.join('\n')
      }
      this.$forceUpdate();
    },
    LinkIMG:LinkIMG,
    selectFile: function(file) {
      this.progress = 0
      this.submitData.file = file
      if(file !== null){
        this.preview = URL.createObjectURL(file)
      }else{
        this.preview = undefined
      }
    },
  },
  props: ['submit',"loading","uploadProcess","editItem"]
}
</script>

<style>

</style>