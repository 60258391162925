<template>
  <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Thêm lô hàng</v-toolbar-title>
        </v-toolbar>
        <v-list 
          three-line
          subheader
        >
          <v-list-item >
            <v-list-item-content >
              <v-list-item-title><h3>Khách hàng</h3></v-list-item-title>
              <v-list-item-subtitle >
                <br>
                <v-row >
                  <v-col
                    cols="3"
                  >
                    <v-responsive class="responsive_khach_hang">
                      <v-card flat>
                        <v-card-text>Tìm khách hàng cũ</v-card-text>
                        <v-row >
                          <v-col 
                            cols=12
                          >
                          <v-text-field
                            v-model="searchCustomerData"
                            :loading="LoadCustomerData"
                            label="Tìm khách hàng cũ"
                            prepend-icon="person"
                            placeholder="Nhập thông tin để tìm"
                            hint="Mã KH, Số điện thoại JP, Tên JP, Mã Sale"
                            persistent-hint
                            clearable
                            @change="SearchCustomer"
                            @keyup.native.enter="SearchCustomer"
                          />
                          </v-col>
                        </v-row>
                        <br>
                      </v-card>
                      <v-divider />
                      <v-card flat>
                        <v-card-text>Thêm khách hàng mới</v-card-text>
                        <v-form 
                          v-model="newCustomerForm"
                        >
                        <v-row>
                          <v-col
                            cols=6
                          >
                            <v-text-field
                              v-model="newCustomer.NameJP"
                              label="Tên JP"
                              :rules="[required('Tên JP')]"
                              @change="GenName"
                            />
                          </v-col>
                          <v-col
                            cols=6
                          >
                            <v-text-field
                              v-model="newCustomer.PhoneJP"
                              label="SĐT JP"
                              @change="GenPhone"
                            />
                          </v-col>
                          <v-col
                            cols=12
                          >
                            <v-text-field
                              v-model="newCustomer.AddressJP"
                              label="Địa chỉ JP"
                            />
                          </v-col>
                          <v-col
                            cols=12
                            lg=6
                            xl=6
                          >
                            <v-text-field
                              v-model="newCustomer.Shipment"
                              label="Phí dịch vụ"
                              disabled
                            />
                          </v-col>
                          <v-col
                            cols=6
                          >
                            <v-text-field
                              v-model="newCustomer.Username"
                              label="Mã khách hàng"
                            />
                          </v-col>
                          <v-col
                            cols=6
                          >
                            <v-text-field
                              v-model="newCustomer.Password"
                              label="Mật khẩu"
                            />
                          </v-col>
                          <v-col
                            cols=6
                          >
                            <v-select
                              v-model="newCustomer.Sale"
                              :items="Sales"
                              :rules="[required('Sale')]"
                              item-value="_id"
                              item-text="Username"
                              label="Danh sách Sale"
                            />
                          </v-col>
                          <v-spacer />
                          <v-col
                            cols=12
                            lg=4
                            xl=4
                          >
                            <v-btn 
                              dark
                              color="green"
                              small
                              @click="AddNewCustomer"
                              :disabled="!newCustomerForm"
                            >
                              Thêm khách hàng
                            </v-btn>
                          </v-col>
                          <v-overlay
                            absolute
                            :value="loadingCreateCustomer"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                              v-if="loadingCreateCustomer"
                            />
                          </v-overlay>
                        </v-row>
                        </v-form>
                      </v-card>
                      <v-overlay 
                        absolute
                        v-if="CustomerID !== null"
                      >
                        <h3>Thao tác này đang bị khóa !!!</h3>
                      </v-overlay>
                    </v-responsive>
                  </v-col>
                  <v-col 
                    cols="9"
                  >
                    <v-stepper
                      v-model="step"
                      vertical
                    >
                      <v-stepper-step
                        :complete="step > 1"
                        step="1"
                      >
                        Lựa chọn khách hàng
                      </v-stepper-step>
                      <v-stepper-content step="1">
                        Vui lòng chọn khách hàng cũ hoặc thêm khách hàng mới
                        <customer :items="customerList" :submit="SelectCustomer" :loading="LoadCustomerData" />
                      </v-stepper-content>
                      <v-stepper-step
                        :complete="step > 2"
                        step="2"
                      >
                        Thông tin kiện hàng
                      </v-stepper-step>
                      <v-stepper-content step="2">
                        <v-form v-model="valid">
                          <v-card outlined>
                          <v-toolbar
                            dark
                            color="primary"
                            flat
                          >
                            <v-toolbar-title>
                              <h3>
                                  Thông tin lô hàng
                              </h3>
                            </v-toolbar-title>
                            <v-spacer />
                            <v-toolbar-title>
                              <v-btn color="deep-orange accent-3" @click="CancelPackage">
                                Chọn lại khách hàng
                              </v-btn>
                            </v-toolbar-title>
                          </v-toolbar>
                            <v-card-title>
                              <v-row>
                                <v-col cols="4">
                                  <v-text-field
                                    v-model="submitData.Code"
                                    :rules="[required('Tên kiện hàng')]"
                                    label="Tên lô hàng"
                                    :disabled="CustomerID === null"
                                  />
                                </v-col>
                                <v-col cols="3" class="d-flex child-flex">
                                  <v-text-field class="btn-toggle-0"
                                    :value="lastest"
                                    label="Tên lô hàng mới nhất"
                                    disabled
                                  />
                                </v-col>
                                <v-col cols="3" class="d-flex child-flex">
                                  <v-btn-toggle class="btn-toggle-1">
                                    <v-btn 
                                    height="40px"
                                    dark
                                    color="green accent-4" @click="AddNewPackage(true)">
                                      <v-icon left>
                                        mdi-plus-box-outline
                                      </v-icon>
                                      Địa chỉ mới
                                    </v-btn>
                                    </v-btn-toggle>
                                    <v-spacer class="spacer_lo_hang"></v-spacer>
                                    <v-btn-toggle class="btn-toggle-1">
                                    <v-btn
                                      height="40px"
                                      dark
                                      @click="CheckDataValid"
                                      color="primary"
                                    >
                                    <v-icon left>
                                        mdi-content-save
                                    </v-icon>
                                      Tạo lô hàng
                                    </v-btn>
                                  </v-btn-toggle>
                                </v-col>
                              </v-row>
                            </v-card-title>
                            <v-card-text class="formlohang">
                              <v-responsive>
                                <v-list single-line>
                                  <template v-for="(pkg,index) in newPackageList">
                                  <!-- <v-card flat :key="index" color="blue lighten-5" outlined class="ma-2 pa-1"> -->
                                    <v-card  flat :key="index" :color="(index % 2) ? 'blue-grey lighten-5':'grey lighten-4'" outlined class="ma-2 pa-1 border"> 
                                      <v-row >
                                          <v-col cols=12>
                                            <v-autocomplete
                                              v-model="SelectCustomerAddressID"
                                              :items="CustomerAddressFixed"
                                              item-value="id"
                                              item-text="text"
                                              label="Địa chỉ cũ"
                                              @change="SelectCustomerAddress(index)"
                                            />
                                          </v-col>
                                          <v-col cols=12 md=6 lg=4 xl=4>
                                            <v-text-field 
                                              v-model="pkg.Shipping.Name"
                                              label="Tên người nhận"
                                              :rules="[required('Tên')]"
                                            />
                                          </v-col>
                                          <v-col cols=12 md=6 lg=4 xl=4>
                                            <v-text-field 
                                              v-model="pkg.Shipping.Phone"
                                              label="Số điện thoại người nhận"
                                              :rules="[required('Số điện thoại')]"
                                            />
                                          </v-col>
                                          <v-col cols=12 md=6 lg=4 xl=4>
                                            <v-text-field 
                                              v-model="pkg.Shipping.Address"
                                              label="Địa chỉ người nhận"
                                              :rules="[required('Địa chỉ')]"
                                            />
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col
                                            cols=12
                                            lg=2
                                            xl=2
                                          >
                                            <v-text-field
                                              v-model="pkg.Stt"
                                              :prefix="GetSuffixPackage"
                                              :rules="[required('STT kiện hàng')]"
                                              label="STT kiện hàng"
                                              disabled
                                            />
                                          </v-col>
                                          <v-col
                                            cols=12
                                            lg=2
                                            xl=2
                                          >
                                            <v-text-field
                                              :value="MaKienHang(index)"
                                              label="Mã kiện hàng"
                                              disabled
                                            />
                                          </v-col>
                                          <v-col
                                            cols=12
                                            lg=2
                                            xl=2
                                          >
                                            <v-text-field
                                              v-model="CustomerUsername"
                                              label="Mã KH"
                                              disabled
                                            />
                                          </v-col>
                                          <v-col
                                            cols=12
                                            lg=3
                                            xl=3
                                          >
                                            <v-select 
                                              v-model="pkg.Type"
                                              :items="PackageType"
                                              item-value="code"
                                              item-text="text"
                                              :rules="[required('Loại')]"
                                              label="Loại"
                                            />
                                          </v-col>
                                          <v-col
                                            cols=12
                                            lg=3
                                            xl=3
                                          >
                                            <v-text-field
                                              v-model="pkg.GW"
                                              label="GW"
                                            />
                                          </v-col>
                                          <v-col
                                            cols=12
                                            lg=2
                                            xl=2
                                          >
                                            <v-text-field
                                              v-model="pkg.D"
                                              label="Dài (cm)"
                                              @change="TinhToanNW(index)"
                                            />
                                          </v-col>
                                          <v-col
                                            cols=12
                                            lg=2
                                            xl=2
                                          >
                                            <v-text-field
                                              v-model="pkg.R"
                                              label="Rộng (cm)"
                                              @change="TinhToanNW(index)"
                                            />
                                          </v-col>
                                          <v-col
                                            cols=12
                                            lg=2
                                            xl=2
                                          >
                                            <v-text-field
                                              v-model="pkg.C"
                                              label="Cao (cm)"
                                              @change="TinhToanNW(index)"
                                            />
                                          </v-col>
                                          <v-col
                                            cols=12
                                            lg=2
                                            xl=2
                                          >
                                            <v-text-field
                                              v-model="pkg.NW"
                                              label="NW"
                                              disabled
                                            />
                                          </v-col>
                                          <v-col
                                            cols=12
                                            lg=2
                                            xl=2
                                          >
                                          <v-btn-toggle dark>
                                            <v-btn height="35px" color="deep-orange accent-3" @click="RemovePackage(index)" v-if="index > 0">
                                              <v-icon>
                                                mdi-delete
                                              </v-icon>
                                            </v-btn>
                                            <v-spacer class="spacer_lo_hang_1"></v-spacer>
                                            <v-btn dark height="35px" color="green accent-4" @click="AddNewPackage(index)">
                                              <v-icon>
                                                mdi-content-copy
                                              </v-icon>
                                            </v-btn>
																						<v-spacer class="spacer_lo_hang_1"></v-spacer>
																						<v-btn dark height="35px" color="green accent-4" @click="doPrint(index)">
																							In
																						</v-btn>
                                          </v-btn-toggle>
                                          </v-col> 
                                      </v-row>
                                        
                                    </v-card>
                                  </template>
                                </v-list>
            
                              </v-responsive>
                              <v-overlay
                                absolute
                                v-if="CustomerID === null"
                              >
                                <h3>Vui lòng chọn khách hàng trước khi thao tác</h3>
                              </v-overlay>
                            </v-card-text>
                            <v-overlay
                              absolute
                              v-if="loadingAdd"
                            >
                                <v-progress-circular
                                  :rotate="360"
                                  :size="100"
                                  :width="15"
                                  :value="process"
                                  color="primary"
                                  dark
                                >
                                  <v-btn text color="primary" dark>{{ process }}%</v-btn>
                                </v-progress-circular>
                            </v-overlay>
                          </v-card>
                        </v-form>
                      </v-stepper-content>
                  </v-stepper>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
</template>

<script>
import validations from '@/plugins/helper/validations'
import { Request } from '@/plugins/helper/fetch'
import { Status } from '@/plugins/helper/dstatus'
import { xoadau } from '@/plugins/helper/string'
import timezone from '@/plugins/helper/timezone'
import customer from '@/components/package/CustomerList.vue'
import {getPDF} from "../../plugins/query/package";
import printJS from "print-js";
export default {
  components: {
    customer,
  },
  data () {
    return {
      step: 1,
      processing:0,
      processTotal:0,
      newCustomerForm: false,
      notice: new Status(this.$swal),
      req: new Request('package'),
      valid: false,
      dialog: false,
      loadingCustomer: false,
      loadingCreateCustomer: false,
      itemsPerPage: 5,
      page: 1,
      tab: 'new',
      CustomerID: null,
      CustomerUsername: '',
      CustomerAddress: [],
      SelectCustomerAddressID: undefined,
      overlay: true,
      submitData:{
        Code: '',
        Customer: '',
        Shipment: this.shipmentData._id,
        PackageList: [],
      },
      packageData:{
        Type: '',
        Customer: '',
        Shipment: '',
        Cost: 0,
        Shipping: null,
        NW: 0,
        GW: 0,
        D: 0,
        R: 0,
        C: 0,
      },
      search: '',
      searchCustomer: '',
      newPackageList: [],
      newAddress:{
        ID: 0,
        Name: '',
        Phone: '',
        Address: ''
      },
      newCustomer: {
        NameJP: '',
        AddressJP: '',
        PhoneJP: '',
        Username: '',
        Password: '123456',
        Shipment: 75000,
        Sale: '',
      },
      newAddressList: [],
      sale: null,
      customerList: [],
      LoadCustomerData: false,
      searchCustomerData: '',
      totalPackages: 0,
      PackageType: [
        {code:'carton',text:'Loose carton'},
        {code:'pallet',text:'Pallet'},
        {code:'crate',text:'Wooden crate'},
        {code:'bag',text:'Bag'},
      ],
      TenKienHang: '',
      STTKienHang: 0
    }
  },
  computed: {
    CustomerAddressFixed(){
      return this.CustomerAddress.map((item,index)=>{
        return {
          id:index,
          text:item.Name+"-"+item.Address+"-"+item.Phone,
        }
      })
    },
    GetPrefixPackage () {
      return timezone.now().format('M')+"-"+this.shipmentData.ShipCount
    },
    GetSuffixPackage () {
      const start = (this.totalPackages === 0) ? 0:this.totalPackages
      const total = start+this.newPackageList.length
      return `${total}/`
    }
  },
  watch: {
    lastest(v){
      console.log(v)
    },
    enable: function(val){
      this.dialog = val
    },
    dialog: function(val){
      this.$emit("update:enable", val);
    },
    CustomerID: function(v){
      if(v !== null){
        this.step = 2
      }
      this.submitData.Customer = v
    },
    step: function(v){
      if(v === 2){
        this.newPackageList = []
        const newData = {
          ...this.packageData,
          Customer:this.CustomerID,
          Shipment:this.shipmentData._id,
          Stt:1,
          Shipping: {
            Name: '',
            Address: '',
            Phone: ''
          },
        }
        this.newPackageList.push(newData)
      }
    },
    newPackageList: function(v){
      this.submitData.PackageList = v
    },
  },
  methods: {
    ...validations,
		doPrint(index){
			/*console.log('Customer:',this.CustomerID);
			console.log('Code:',this.MaKienHang(index));
			console.log('Shipment:',this.shipmentData.Type);
			console.log('newPackageList:',this.newPackageList[index]);
			console.log('GW:',this.newPackageList[index].GW);
			console.log('NW:',this.newPackageList[index].NW);*/
			this.loading = true;
			getPDF({Code: this.MaKienHang(index), Customer: this.CustomerID, Shipment: this.shipmentData.Type, GW: this.newPackageList[index].GW, NW: this.newPackageList[index].NW}).then(resp=>{
				const {error} = resp;
				if(error){
					this.notice.error(error);
				}else{
					const pdfUrl = URL.createObjectURL(resp);
					printJS(pdfUrl);
				}
			}).finally(()=>{
				this.loading = false;
			})
		},
    SelectCustomerAddress(index){
      const ID = this.SelectCustomerAddressID;
      const Data = this.CustomerAddress;
      this.newPackageList[index].Shipping = {
        Name: Data[ID].Name,
        Address: Data[ID].Address,
        Phone: Data[ID].Phone,
      }
      this.SelectCustomerAddressID = undefined;
    },
    CheckDataValid(){
      let Failed = []
      if(!this.submitData.Code) return this.notice.error(`Vui lòng điền tên kiện hàng !!!`)
      // const CurrentCode = this.submitData.Code
      const newSubmit = this.newPackageList.map((item,index)=>{
        const {Type,Shipping} = item
        if(Type === '' || Type === null || Type === undefined){
          this.notice.error(`Vui lòng chọn loại cho kiện hàng ${this.MaKienHang(index)}`)
          Failed.push(index)
          return
        }
        if(!Shipping.Name || !Shipping.Phone || !Shipping.Address){
          this.notice.error(`Vui lòng điền thông tin người nhận cho kiện hàng  ${this.MaKienHang(index)}`)
          Failed.push(index)
          return
        }
        return {
          ...item,
          Code: this.MaKienHang(index)
        }
      })
      if(Failed.length > 0) return
      this.submitForm(newSubmit)
    },
    CancelPackage(){
      this.CustomerID = null
      this.step = 1
    },
    RemovePackage(index){
      this.newPackageList.splice(index,1)
    },
    AddNewPackage(newAddress){
      const ID = (newAddress === true) ? (this.newPackageList.length - 1):newAddress
      const PackageData = this.newPackageList[ID]
      const {Shipping} = PackageData
      // if(Type === '' || Type === null || Type === undefined){
      //   this.notice.error("Vui lòng chọn loại các kiện cũ trước khi thêm kiện mới")
      //   return
      // }
      // if(!Shipping.Name || !Shipping.Phone || !Shipping.Address){
      //   this.notice.error("Vui lòng điền thông tin người nhận các kiện cũ trước khi thêm kiện mới")
      //   return
      // }
      const newShipping = {
          Name: '',
          Address: '',
          Phone: ''
        }
      const ShippingData = (newAddress === true) ? newShipping:Shipping
      const newData = {
        ...this.packageData,
        Customer:this.CustomerID,
        Shipment:this.shipmentData._id,
        Stt:this.newPackageList.length+1,
        Shipping: ShippingData,
      }
      this.newPackageList.push(newData)
    },
    MaKienHang(index) {
      const start = (this.totalPackages === 0) ? 0:this.totalPackages
      const total = start+this.newPackageList.length
      const stt = (this.newPackageList[index] !== undefined) ? this.newPackageList[index].Stt:1
      const prefix = (this.shipmentData.NameAuto) ? this.shipmentData.NameAuto:timezone.now().format('M')+this.shipmentData.ShipCount
      return prefix+'-'+this.submitData.Code+'-'+total+'/'+stt
    },
    TinhToanNW(index){
      const ThongTin = {
        D: parseInt(this.newPackageList[index].D),
        R: parseInt(this.newPackageList[index].R),
        C: parseInt(this.newPackageList[index].C),
      }
      if(parseInt(ThongTin.D) === 0 || parseInt(ThongTin.R) === 0 || parseInt(ThongTin.C) === 0) return
      const the_tich = parseInt(ThongTin.D)*parseInt(ThongTin.R)*parseInt(ThongTin.C)
      const ketqua = (parseFloat(the_tich)/6000).toFixed(2);
      this.newPackageList[index].NW = isNaN(ketqua) ? 0:ketqua
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    GenName(){
      const NameJP = this.newCustomer.NameJP.trim().split(' ')
      this.newCustomer.Username = xoadau(NameJP[NameJP.length-1])
    },
    GenPhone(){
      const LastPhone = this.newCustomer.PhoneJP.trim().substr(this.newCustomer.PhoneJP.length - 4);
      this.newCustomer.Username = this.newCustomer.Username+LastPhone
    },
    AddVN() {
      this.newAddress.ID = this.newAddressList.length
      if(this.newAddress.Name === '' || this.newAddress.Phone === '' || this.newAddress.Address === ''){
        this.notice.error("Điền đầy đủ thông tin !!!")
        return
      }
      this.newAddressList.push(this.newAddress)
      this.newAddressList.sort()
      this.newAddress = { Name: '', Phone: '', Address: '' }
    },
    SearchCustomer(){
      if(this.searchCustomerData.length === 0 || this.searchCustomerData.trim() === ''){
        this.notice.error("Vui lòng điền thông tin để tìm kiếm !!!")
        return
      }
      this.LoadCustomerData = true
      this.req.search(`customer`,{'Search':this.searchCustomerData}).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.customerList = json.data.filter(v=>!v.Locked);
        }else{
          const msg = json.error || "Không thể tải danh sách khách hàng !!!"
          this.notice.error(msg)
        }
      }).catch(e=>{
        this.notice.error(e)
      }).finally(()=>{
        this.LoadCustomerData = false
      })
    },
    SearchStaff() {
      this.LoadCustomerData = true
      this.req.fetch(`customerBySale/${this.sale}`).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.customerList = json.data
        }else{
          const msg = json.error || "Không thể tải danh sách khách hàng !!!"
          this.notice.error(msg)
        }
      }).catch(e=>{
        this.notice.error(e)
      }).finally(()=>{
        this.LoadCustomerData = false
      })
    },
    SelectCustomer(customer){
      const CustomerID = customer._id;
      const CustomerUsername = customer.Username;
      const CustomerAddress = customer.AddressVN;
      const request = {
        Shipment: this.shipmentData._id,
        Customer: CustomerID
      }
      this.req.search('packageByCustomer',request).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.CustomerID = CustomerID
          this.CustomerUsername = CustomerUsername
          this.CustomerAddress = CustomerAddress
          // this.totalPackages = json.total
          this.totalPackages = 0
        }else{
          const e = json.error || "Không thể chọn khách hàng này !!!"
          this.notice.error(e)
        }
      }).catch(e=>{
        this.notice.error(e)
      })
    },
    AddNewCustomer() {
      this.totalPackages = 0
      this.loadingCreateCustomer = true
      const req = new Request('customer')
      req.create(this.newCustomer).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.CustomerID = json.data._id
          this.CustomerUsername = json.data.Username
          this.step = 2
        }else{
          const e = json.error || "Không thể thêm khách hàng !!!"
          this.notice.error(e)
        }
      }).catch(e=>{
        this.notice.error(e)
      }).finally(()=>{
        this.loadingCreateCustomer = false
      })
    }
  },
  props: ["enable","submitForm","Sales","shipmentData","loadingAdd","process", "lastest"]
}
</script>

<style>
  .vrow_test {
    position: relative;
  }
</style>