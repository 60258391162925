<template>
  <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card height="70vh">
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Thêm dữ liệu</v-toolbar-title>
        </v-toolbar>
        <v-list
          single-line
        >
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col
                  cols="1"
                >
                  <v-btn @click="dialogCreateItem = true" dark color="green">
                    Thêm mới
                  </v-btn>
                  <v-dialog
                    v-model="dialogCreateItem"
                    persistent
                    width="100%"
                    transition="dialog-bottom-transition"
                  >
                    <v-system-bar
                      dark
                      color="primary"
                      height="50"
                    >
                      <v-btn
                        icon
                        dark
                        @click="dialogCreateItem = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-system-bar>
                    <ItemControl :loading="loadingItems" :submit="submitItem" :uploadProcess="uploadProcess" />
                  </v-dialog>
                </v-col>
                <v-col
                  cols="7"
                >
                  <v-text-field 
                    v-model="janCode" 
                    label="Tìm kiếm"
                    ref="input"
                    :rules="[required('Thông tin tìm kiếm')]"
                    @change="Search(janCode)"
                    @keyup.native.enter="Search(janCode)"
                  />
                </v-col>
                <v-col
                  cols="4"
                >
                  <v-text-field v-model="NumOfItem" clearable label="Số lượng" :rules="[onlyNumber('Số lượng'),min(0)]" />
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-responsive max-height="600px" class="overflow-y-auto">
                <List :items="items" :loading="loading" :controls="controls" :nums="NumOfItem" />
                <v-overlay
                  absolute
                  :value="loading"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="loading"
                  />
                </v-overlay>
              </v-responsive>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="DanhSach.length > 0">
            <v-list-item-content>
              <v-toolbar
                dark
                color="primary"
              >
                <v-toolbar-title>Danh sách hàng đã nằm trong kiện</v-toolbar-title>
              </v-toolbar>
              <v-responsive max-height="400" class="overflow-y-auto">
                <v-data-table
                  :headers="headers"
                  :items="DanhSach"
                  :search="janCode"
                  loading-text="Đang tải dữ liệu..."
                  :footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[5]}"
                  :items-per-page="5"
                >
                  <template v-slot:[`item.SL`]="{ item }">
                    {{ GetSL(item.index) }}
                  </template>
                  <template v-slot:[`item.productImages`]="{ item }">
                    <template v-if="item.productImages.length > 0">
                      <v-carousel height="300px" width="300px" hide-delimiters>
                        <v-carousel-item
                          v-for="(img,index) in item.productImages"
                          :key="index"
                          reverse-transition="fade-transition"
                          transition="fade-transition"
                        >
                          <v-img :src="LinkIMG(img.uri)" aspect-ratio="2.5" contain />
                        </v-carousel-item>
                      </v-carousel>
                    </template>
                    <v-img v-else src="/no-image.png" aspect-ratio="2.5" contain />
                  </template>
                </v-data-table>
              </v-responsive>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
</template>

<script>
import validations from '@/plugins/helper/validations'
import List from '@/components/detail/ItemList.vue'
import ItemControl from '@/components/detail/control.vue'
import {LinkIMG} from '@/plugins/helper/string'

export default {
  components:{
    List,
    ItemControl,
  },
  data () {
    return {
      dialogCreateItem: false,
      dialog: false,
      searchValid: false,
      janCode: '',
      NumOfItem: 0,
      headers: [
        { text: 'Số lượng', value: 'SL', width: "75px", fixed: true },
        { text: 'Jan Code', value: 'janCode', align: 'center',width: "150px"},
        { text: 'Images', value: 'productImages', align: 'left',width: "300px"},
      ]
    }
  },
  computed:{
    DanhSach(){
      return this.list.map((item,index)=>{
        const r = {
          ...item,
          index: index
        }
        return r
      }).reverse()
    }
  },
  watch: {
    enable: function(val){
      this.dialog = val
    },
    dialog: function(val){
      if(val === true){
        this.$nextTick(() => {
          setTimeout(() => {
            this.focus()
          })
        })
      }
      this.$emit("update:enable", val);
    },
    NumOfItem: function(val){
      this.$emit("update:nums", val);
    },
    nums: function(v){
      this.NumOfItem = v
    },
    added(v){
      if(v===true){
        this.janCode = ''
        this.focus()
        this.$emit("update:added", false);
      }
    },
  },
  methods: {
    ...validations,
    LinkIMG:LinkIMG,
    focus(){
      return this.$refs.input.$refs.input.focus()
    },
    GetSL(index){
      return this.sl[index]
    },
  },
  mounted(){
    this.NumOfItem = this.nums
  },
  props: ["enable","Search","loading","items", "controls","nums","list","sl","added","loadingItems","submitItem","uploadProcess"]
}
</script>

<style>
</style>