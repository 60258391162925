<template>
  <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="600px"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Cập nhật số lượng</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="submitForm"
              :loading="loading"
            >
              Lưu
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-subheader>Số lượng</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-text-field v-model="NumOfItem" label="Số lượng" :rules="[onlyNumber('Số lượng'),min(0)]" />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-overlay
          absolute
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loading"
          />
        </v-overlay>
      </v-card>
    </v-dialog>
</template>

<script>
import validations from '@/plugins/helper/validations'
export default {
  data () {
    return {
      dialog: false,
      NumOfItem: 0,
    }
  },
  watch: {
    enable: function(val){
      this.dialog = val
    },
    dialog: function(val){
      this.$emit("update:enable", val);
    },
    NumOfItem: function(val){
      if(val !== '' && val > 0 && val !== null){
        this.$emit("update:nums", val);
      }
    },
    nums: function(val){
      this.NumOfItem = val
    }
  },
  methods: {
    ...validations,
  },
  mounted(){
    this.NumOfItem = this.nums
  },
  props: ["enable","submitForm","loading","nums"]
}
</script>

<style>
</style>