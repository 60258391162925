<template>
  <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="600px"
    >
      <v-card>
        <v-form v-model="valid">
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Gộp kiện hàng</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="submitForm(submitData)"
              :loading="loading"
              :disabled="!valid"
            >
              Gộp
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-subheader>Danh sách kiện hàng</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-tabs
                  v-model="tab"
                  background-color="#0f2e8c"
                  center-active
                  dark
                >
                  <v-tab href="#old" :disabled="list.length === 0">Kiện hàng gộp</v-tab>
                  <v-tab href="#new">Tạo mới</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item
                    value="old"
                  >
                    <br>
                    <v-card flat>
                      <v-select
                        v-model="CapNhat.Packages"
                        :items="list"
                        item-value="_id"
                        item-text="Code"
                        label="Mã kiện hàng"
                        :rules="[required('Mã kiện hàng')]"
                      />
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                    value="new"
                  >
                    <br>
                    <v-card flat>
                      <v-text-field
                        v-model="CapNhat.Packages"
                        label="Mã kiện hàng gộp"
                        hint="Bắt đầu bằng TE"
                        persistent-hint
                        :rules="[packageCode('Mã kiện hàng')]"
                      />
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-overlay
          absolute
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loading"
          />
        </v-overlay>
        </v-form>
      </v-card>
    </v-dialog>
</template>

<script>
import validations from '@/plugins/helper/validations'
export default {
  data () {
    return {
      valid:false,
      dialog: false,
      CapNhat: {
        'Packages':'',
      },
      Package: '',
      tab:'',
    }
  },
  computed:{
    submitData(){
      return  {...this.CapNhat,'Action':this.tab}
    }
  },
  watch: {
    enable: function(val){
      this.dialog = val
    },
    dialog: function(val){
      this.$emit("update:enable", val);
    },
    tab: function(v){
      if(v === 'new')
      {
        this.Package = ''
        this.CapNhat.Packages = ''
      }
    },
    Package: function(val){
      if(val.length > 0){
        this.CapNhat.Packages = val
      }
    }
  },
  methods: {
    ...validations,
  },
  mounted(){
  },
  props: ["enable","submitForm","loading","list"]
}
</script>

<style>
</style>