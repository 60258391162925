<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="100%"
      transition="dialog-bottom-transition"
    >
      <v-system-bar
        dark
        color="primary"
        height="50"
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>
      <v-responsive aspect-ratio="16/9">
        <v-form v-model="formValid">
        <v-card>
          <v-card-title></v-card-title>
          <v-row>
            <v-col 
              cols=6
            >
              <v-card-text>
                <v-row>
                  <v-col
                    cols="3"
                  >
                    <v-text-field
                      v-model="editItem.janCode"
                      label="Jan Code"
                      :rules="[required('Jan Code')]"
                      :disabled="mode==='edit'"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                  >
                    <v-currency-field
                      v-model="editItem.price"
                      label="Price"
                      :rules="[required('Price')]"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                  >
                    <v-autocomplete
                      v-model="editItem.countryCode"
                      :items="Countries"
                      item-value="code"
                      item-text="text"
                      label="Origin"
                      :rules="[required('Origin')]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-textarea
                      v-model="editItem.name"
                      label="En Name"
                      rows="2"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-textarea
                      v-model="editItem.jaName"
                      label="JP Name"
                      rows="2"
                      :rules="[required('JP Name')]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-textarea
                      v-model="editItem.viName"
                      label="Vi Name"
                      rows="2"
                      :rules="[required('Vi Name')]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-textarea
                      v-model="editItem.description"
                      label="Description"
                      :rules="[required('Description')]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-textarea
                      v-model="editItem.enDescription"
                      label="En Description"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-textarea
                      v-model="editItem.viDescription"
                      label="VI Description"
                      :rules="[required('Vi Description')]"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>    
            <v-col
              cols=6
            >
              <v-card-title>
                <v-row>
                  <v-col
                    cols=6
                  >
                    <v-textarea
                      v-model="editItem.LinkSP"
                      label="Link SP"
                    />
                  </v-col>
                  <v-col
                    cols=3
                  >
                    <v-file-input
                      ref="inputFile"
                      show-size
                      label="Tải hình ảnh"
                      accept="image/*"
                      @change="selectFile"
                    />
                  </v-col>
                  <v-col
                    cols="auto"
                  >
                    <v-btn
                      :disabled="!formValid"
                      color="green"
                      large
                      dark
                      @click="UpdateItem(editItem)"
                    >
											Lưu
                    </v-btn>
                  </v-col>
									<v-col
										cols="auto"
									>
										<v-btn
											:disabled="!formValid"
											color="green"
											large
											dark
											@click="UpdateItem(editItem,true)"
										>
											Lưu & Chọn
										</v-btn>
									</v-col>
									<v-col
										cols="auto"
									>
										<v-btn
											:disabled="!formValid"
											color="green"
											large
											dark
											@click="UpdateItem(editItem,true, true)"
										>
											Tạo mới & Chọn
										</v-btn>
									</v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-img v-if="preview" :src="preview" aspect-ratio="1.3" />
              </v-card-text>
            </v-col>
          </v-row>
          <Loading :status="loadingItems" />
        </v-card>
        </v-form>
      </v-responsive>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      loading-text="Đang tải dữ liệu..."
      :footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[5]}"
      :items-per-page="5"
      :sort-by="['ID']"
      :sort-desc="[true]"
    >
      <template v-slot:[`item.productImages`]="{ item }">
        <template v-if="item.productImages.length > 0">
          <v-carousel height="200px" width="200px" hide-delimiters>
            <v-carousel-item
              v-for="(img,index) in item.productImages"
              :key="index"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
              <v-img :src="LinkIMG(img.uri)" aspect-ratio="1.3" contain />
            </v-carousel-item>
          </v-carousel>
        </template>
        <v-img v-else src="/no-image.png" aspect-ratio="2.5" contain />
      </template>
      <template v-slot:[`item._id`]="{ item }">
        <v-btn small color="blue" @click="Chon(item)" dark :disabled="!valid">
          Chọn
        </v-btn>
        <br><br>
        <v-btn small color="green" @click="Sua(item)" dark>
          Sửa
        </v-btn>
      </template>
    </v-data-table>
    <Loading :status="loadingItems" />
  </div>
</template>

<script>
// import ItemControl from '@/components/detail/control.vue'
import validations from '@/plugins/helper/validations'
import Countries from '@/plugins/helper/countries.json'
import Loading from '@/components/base/Loading.vue'
import {LinkIMG} from '@/plugins/helper/string'
import {Status} from '@/plugins/helper/dstatus'
import {Request} from '@/plugins/helper/fetch'
export default {
  components: {
    // ItemControl,
    Loading,
  },
  data () {
    return {
      formValid: false,
      dialog: false,
      loadingItems: false,
      search: '',
      description: '',
      notice: new Status(this.$swal),
      req: new Request('detail'),
      preview: undefined,
      editItem: {

      },
      headers: [
        { text: 'Thao tác', value: '_id', align: 'center',width: "150px",class:"grey lighten-2 black--text" },
        { text: 'Images', value: 'productImages', align: 'left',width: "300px" ,class:"grey lighten-2 black--text" },
				{ text: 'Price', value: 'price', align: 'left',width: "150px" ,class:"grey lighten-2 black--text" },
        { text: 'EN Name', value: 'name', align: 'left',width: "150px" ,class:"grey lighten-2 black--text" },
        { text: 'JP Name', value: 'jaName', align: 'left',width: "150px" ,class:"grey lighten-2 black--text" },
        { text: 'VI Name', value: 'viName', align: 'left',width: "150px" ,class:"grey lighten-2 black--text" },
        { text: 'Description', value: 'description', align: 'left', width: "300px",class:"grey lighten-2 black--text" },
        { text: 'EN Description', value: 'enDescription', align: 'left',width: "300px",class:"grey lighten-2 black--text" },
        { text: 'VI Description', value: 'viDescription', align: 'left',width: "300px",class:"grey lighten-2 black--text" },
      ],
      uploadProcess: 0,
    }
  },
  computed:{
    mode(){
      return (this.editItem._id) ? 'edit':'new'
    },
    valid(){
      if(this.nums === null) return false
      if(this.nums <= 0) return false
      return true
    },
    Countries(){
      return Object.keys(Countries).map((key) => ({
        'code':key,
        'text':Countries[key]
      }))
    }
  },
  watch: {
  },
  props: ['items','controls',"nums"],
  mounted(){
  },
  methods:{
    ...validations,
    LinkIMG,
    selectFile: function(file) {
      this.progress = 0
      this.submitData.file = file
      if(file !== null){
        this.preview = URL.createObjectURL(file)
      }else{
        this.preview = undefined
      }
    },
    Sua(item){
      const ID = item._id
      this.loadingItems = true
      this.req.fetch('byID/'+ID).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        const {success,error,data} = json
        if(success && data){
          const {janCode, name, jaName, viName, price, countryCode, description, viDescription, enDescription, unit, productImages, _id} = data
          this.editItem = {janCode, name, jaName, viName, price, countryCode, description, viDescription, enDescription, unit, productImages, _id}
          if(data.productImages.length > 0){
            if(data.productImages[0].uri){
              this.preview = this.LinkIMG(data.productImages[0].uri)
            }
          }else{
            this.preview = '/no-image.png'
          }
          if(data.productLinks.length > 0){
            let linkANH = []
            data.productLinks.map(item=>{
              linkANH.push(item.link)
            })
            this.editItem.LinkSP = linkANH.join('\n')
            console.log(this.editItem)
          }
          this.dialog = true
          this.$forceUpdate();
        }else{
          const e = error || "Không thể tải item !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingItems = false
      })
    },
    UpdateItem(submitData,selected = false, createNew = false){
      this.loadingItems = true
      const {janCode} = submitData;
			let customUrl = 'updateItem';
			if(createNew){
				submitData['_id'] = undefined;
				submitData['id'] = undefined;
				customUrl = 'createItem';
			}
      this.req.UploadItem(janCode,submitData,(event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      },customUrl).then(axios=>{
        const {success,error, data} = axios.data
        if(success){
          this.notice.success("Đã cập nhật Jan Code thành công !!!")
          this.dialog = false
					if(selected){
						if(createNew){
							submitData['_id'] = data['_id'];
							submitData['id'] = data['_id'];
						}
						this.Chon(submitData)
					}
        }else{
          const e = error || "Không thể cập nhật Jan Code !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingItems = false
      })
    },
    Chon(item){
      this.controls[0].do(item)
    },
  }
}
</script>

<style>
  .desc {
    max-height: 50px;
  }
</style>