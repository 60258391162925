<template>
  <tr>
    <td></td>
    <td></td>
    <td class="text-h5 text-center">
      {{TongKH}}
    </td>
    <td class="text-h6 text-center">
      {{ DoiTien(TongCuocSale) }}
    </td>
    <td>
      <v-select
          v-model="Filter.Status"
          :items="StatusList"
          item-value="_id"
          item-text="Code"
          label="Lọc theo trạng thái"
          @change="UpdateFilter"
      />
    </td>
		<td></td>
    <td class="text-h5 text-center">
      <v-autocomplete
        v-model="Filter.Sale"
        :items="SaleList"
        item-value="_id"
        item-text="Code"
        label="Mã Sale"
        @change="UpdateFilter"
      />
    </td>
    <td class="text-h5 text-center">
      <v-autocomplete
        v-model="Filter.Customer"
        :items="CustomerList"
        item-value="_id"
        item-text="Code"
        label="Mã KH"
        @change="UpdateFilter"
      />
    </td>
    <td></td>
    <td></td>
    <td></td>
    <td class="text-h5 text-center">
			{{ (TongNW).toFixed(2) }}
		</td>
    <td class="text-h5 text-center">
      {{ (TongGW).toFixed(2) }}
    </td>
    <td class="text-h6 text-center">
      {{ DoiTien(TongCuocGoc) }}
    </td>
    <td class="text-h5 text-center">
      {{ (TongSL) }}
    </td>
		<td></td>
    <td class="text-h6 text-center">
      {{ DoiTien(TongLoiNhuan) }}
    </td>
    <td></td>
    <td></td>
    <td></td>
		<td></td>
		<td></td>
  </tr>
</template>

<script>
import { DoiTien } from '@/plugins/helper/string'
export default {
  data () {
    return {
      Filter:{
        Customer:null,
        Sale:null,
        Status:null,
      },
    }
  },
  computed: {
    packageFilter() {
      return {...this.$store.state.packageFilter}
    }
  },
  watch:{
    packageFilter(v){
      this.Filter = Object.assign({},v);
    }
  },
  methods:{
    DoiTien,
    UpdateFilter(){
      this.$store.dispatch('PackageFilter', this.Filter);
    }
  },
  mounted(){
    this.Filter = Object.assign({},this.packageFilter);
  },
  props:["TongNW","TongGW","TongSL","TongKH","TongCuocSale", "TongCuocGoc", "TongLoiNhuan", "CustomerList","SaleList", "StatusList"]
}
</script>

<style>

</style>