<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    loading-text="Đang tải dữ liệu..."
    :footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[5]}"
    :items-per-page="5"
    :sort-by="['ID']"
    :sort-desc="[true]"
  >
    <template v-slot:[`item._id`]="{ item }">
      <v-btn
        icon
        color="green"
        @click="submit(item)"
      >
        <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data () {
    return {
      search: '',
      headers: [
        { text: 'Mã KH', align: 'center', sortable: true,value: 'Username',width:"10%",class:"grey lighten-2 black--text" },
        { text: 'Tên JP', align: 'center', sortable: true,value: 'NameJP',width:"10%",class:"grey lighten-2 black--text" },
        { text: 'SĐT JP', align: 'center', sortable: true,value: 'PhoneJP',width:"10%",class:"grey lighten-2 black--text" },
        { text: 'Địa chỉ JP', align: 'center', sortable: true,value: 'AddressJP',width:"15%",class:"grey lighten-2 black--text" },
        { text: 'Thao tác', align: 'center', sortable: false,value: '_id',width:"15%",class:"grey lighten-2 black--text" },
      ],
    }
  },
  watch: {
  },
  props: ['items','submit','loading'],
  mounted(){
  },
  methods:{
  }
}
</script>

<style>

</style>