import { Request } from '@/plugins/helper/fetch'
const req = new Request('package')
async function remove(item){
  const resp = await req.delete(item._id)
  return await resp.json()
}
async function create(requestData){
  if(requestData.Code === '') throw Error('Mã kiện hàng không hợp lệ !!!')
  if(requestData.Customer === '') throw Error('Thông tin khách hàng không hợp lệ !!!')
  if(requestData.Shipment === '') throw Error('Thông tin Shipment không hợp lệ !!!')
  if(requestData.Shipping === '') throw Error('Địa chỉ nhận hàng không hợp lệ !!!')
  if(requestData.Type === '') throw Error('Loại kiện hàng không hợp lệ !!!')
  const resp = await req.create(requestData)
  return await resp.json()
}
async function rPackages(id){
  const resp = await req.Go(id)
  return await resp.json()
}
async function uTrack(id,data)
{
  const resp = await req.update(id,data)
  return await resp.json()
}
async function merge(id,data)
{
  const resp = await req.Gop(id,data)
  return await resp.json()
}
async function cShipment(id,CustomShipment)
{
  const data = {
    CustomShipment: CustomShipment
  }
  const resp = await req.update(id,data)
  return await resp.json()
}
async function uPaymentStatus(id,Status){
  const data = {
    Status: Status
  }
  const resp = await req.update(id,data)
  return await resp.json()
}
async function uPaymentStatusList(ids,Status){
  const data = {
    Package: ids,
    Status: Status
  }
  const resp = await req.patch('update-status',data)
  return await resp.json()
}
async function Note(id,Note){
  const data = {
    Note
  }
  const resp = await req.update(id,data)
  return await resp.json()
}
export async function getPDF({id, Code, Customer, Shipment, GW, NW}){
  let resp = null;
  if(id) {
    resp = await req.get('label/' + id);
  }else{
    resp = await req.post('label',{Code, Customer, Shipment, GW, NW});
  }
  if(resp.headers.get("content-type").toLowerCase() === "application/pdf"){
    return await resp.blob()
  }
  else{
    return await resp.json()
  }
}
export default {
  remove,
  create,
  merge,
  rPackages,
  uTrack,
  cShipment,
  uPaymentStatus,
  Note,
  getPDF,
  uPaymentStatusList,
}